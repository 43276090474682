<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-04-07 19:46:22
 * @Description: 监控视频画面播放
-->
<template>
  <div class="big_player_mask">
    <div class="big_player_inner">
      <div class="big_player_close" @click="closeBigPlayer"></div>
      <div class="video_area">
        <VideoPlayer :source="sourceData.source" />
        <div class="big_player_tool">
          <div class="big_player_zoom">
            <div class="reduce_zoom" @click="changeOperateCamera(2)"></div>
            <div class="progress_zoom">
              <!-- <div class="zoom_long" style="width: 50%">
              <div class="zoom_round"></div>
            </div> -->
            </div>
            <div class="add_zoom" @click="changeOperateCamera(1)"></div>
          </div>

          <div class="big_player_direct">
            <div
              class="direct_btn camera_up"
              @click="changeOperateDirect(1)"
            ></div>
            <div
              class="direct_btn camera_bottom"
              @click="changeOperateDirect(2)"
            ></div>
            <div
              class="direct_btn camera_left"
              @click="changeOperateDirect(3)"
            ></div>
            <div
              class="direct_btn camera_right"
              @click="changeOperateDirect(4)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer.vue";
import { operateCamera, operateDirect } from "@/api/hydrometeor/index.js";

export default {
  name: "BigPlayer",
  data() {
    return {
      interval: 3,
    };
  },
  computed: {},
  components: {
    VideoPlayer,
  },
  props: {
    sourceData: {
      type: Object,
      default: function () {
        return {
          source: "",
          channelId: "",
        };
      },
    },
  },
  methods: {
    changeOperateCamera(direct) {
      operateCamera({
        extend: "",
        step: "1",
        operateType: "1",
        direct: direct,
        channelId: this.sourceData.channelId,
        command: "1",
      }).then((res) => {});
    },
    changeOperateDirect(direct) {
      operateDirect({
        extend: "",
        stepX: "6",
        stepY: "6",
        direct: direct,
        channelId: this.sourceData.channelId,
        command: "1",
      }).then((res) => {});
    },
    closeBigPlayer() {
      this.$emit("closeBigPlayer");
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
.big_player_mask {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  .big_player_inner {
    width: 780px;
    height: 545px;
    background: #2d3246;
    border: 2px solid #566198;
    position: fixed;
    z-index: 998;
    left: 50%;
    top: 50%;
    margin-left: -390px;
    margin-top: -250px;
  }
  .video_area {
    height: 438px;
  }
  .big_player_close {
    width: 40px;
    height: 40px;
    background: url(../../assets/icons/close_icon11.png) no-repeat;
    background-size: 100%;
    position: absolute;
    right: -20px;
    top: -20px;
    z-index: 2;
    cursor: pointer;
  }
  .big_player_tool {
    display: flex;
    padding: 20px;
    margin-top: 34px;
    .big_player_zoom {
      display: flex;
      .reduce_zoom {
        width: 22px;
        height: 22px;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          display: block;
          width: 18px;
          height: 4px;
          position: absolute;
          left: 0;
          background: #52afe8;
          transition: background 0.2s ease-out;
        }
        &:hover {
          &::before {
            background: #2878ff;
          }
        }
      }
      .progress_zoom {
        width: 540px;
        height: 4px;
        background: #11111b;
        margin: 0 20px;
        .zoom_long {
          position: relative;
          width: 0%;
          height: 4px;
          background: #cfe0ff;
          transition: background 0.3s ease-out;
        }
        .zoom_round {
          width: 22px;
          height: 22px;
          background: #cfe0ff;
          border-radius: 100%;
          position: absolute;
          right: -11px;
          top: -10px;
        }
      }

      .add_zoom {
        width: 22px;
        height: 22px;
        position: relative;
        cursor: pointer;
        &:hover {
          &::before {
            background: #2878ff;
          }
          &::after {
            background: #2878ff;
          }
        }
        &::before {
          content: "";
          display: block;
          width: 18px;
          height: 4px;
          background: #52afe8;
          position: absolute;
          left: 0px;
          transition: background 0.2s ease-out;
        }
        &::after {
          content: "";
          display: block;
          width: 18px;
          height: 4px;
          background: #52afe8;
          transform: rotate(90deg);
          top: 0px;
          transition: background 0.2s ease-out;
        }
      }
    }

    .big_player_direct {
      width: 78px;
      height: 78px;
      background: #39405d;
      border-radius: 50%;
      position: relative;
      top: -38px;
      left: 20px;
      .direct_btn {
        position: absolute;
        cursor: pointer;
        transition: all 0.2s ease-out;
        &:hover {
          opacity: 0.8;
        }
      }
      .camera_up {
        width: 30px;
        height: 16px;
        background: url(../../assets/icons/up_icon@2x.png) no-repeat center;
        background-size: 100%;
        left: 50%;
        top: 2px;
        margin-left: -15px;
      }
      .camera_bottom {
        width: 30px;
        height: 16px;
        background: url(../../assets/icons/down_icon@2x.png) no-repeat center;
        background-size: 100%;
        left: 50%;
        bottom: 2px;
        margin-left: -15px;
      }
      .camera_left {
        width: 16px;
        height: 30px;
        background: url(../../assets/icons/Left_icon@2x.png) no-repeat center;
        background-size: 100%;
        left: 2px;
        top: 50%;
        margin-top: -15px;
      }
      .camera_right {
        width: 16px;
        height: 30px;
        background: url(../../assets/icons/right_icon@2x.png) no-repeat center;
        background-size: 100%;
        right: 2px;
        top: 50%;
        margin-top: -15px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 26px;
        height: 26px;
        background: #cfe0ff;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        margin-left: -13px;
        margin-top: -13px;
      }
    }
  }
}
</style>
