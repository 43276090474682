<!--
 * @Author: ch3nh2
 * @Date: 2022-04-14 19:10:43
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-14 15:50:47
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/VideoTools.vue
 * @Description: 视频监控控制器
-->
<template>
  <div class="player_tool">
    <div class="player_zoom">
      <div class="reduce_zoom" @click="controlZoom('A50F0120000010E5')"></div>
      <div class="add_zoom" @click="controlZoom('A50F0110000010D5')"></div>
    </div>

    <div class="player_direct">
      <div class="direct_btn camera_up" @click="controlPosition('A50F0108001F00DC')"></div>
      <div class="direct_btn camera_bottom" @click="controlPosition('A50F0104001F00D8')"></div>
      <div class="direct_btn camera_left" @click="controlPosition('A50F01021F0000D6')"></div>
      <div class="direct_btn camera_right" @click="controlPosition('A50F01011F0000D5')"></div>
    </div>
  </div>
</template>
<script>

import { operateCamera, operateDirect } from "@/api/hydrometeor/index.js";
import axios from 'axios'
export default {
  name: "VideoTools",
  data() {
    return {};
  },
  props: {
    sourceData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    /** 大华云台控制start */
    changeOperateCamera(direct) {
      // 参数说明：放大:1 缩小:2
      operateCamera({
        extend: "",
        step: "1",
        operateType: "1",
        direct: direct,
        channelId: this.sourceData.channelCode,
        command: "1",
      }).then((res) => { });
    },
    changeOperateDirect(direct) {
      // 参数说明：上:1 下:2 左:3 右:4
      operateDirect({
        extend: "",
        stepX: "6",
        stepY: "6",
        direct: direct,
        channelId: this.sourceData.channelCode,
        command: "1",
      }).then((res) => { });
    },
    /** 大华云台控制end */

    controlPosition(code) {
      const { deviceId, channelCode } = this.sourceData;
      axios({
        url: this.$config.m7sUrl + '/gb28181/api/control',
        params: {
          id: deviceId,
          channel: channelCode,
          ptzcmd: code
        }
      })
      setTimeout(() => {
        axios({
          url: this.$config.m7sUrl + '/gb28181/api/control',
          params: {
            id: deviceId,
            channel: channelCode,
            ptzcmd: 'A50F0100000000B5'
          }
        })
      }, 500);
    },
    controlZoom(code) {
      const { deviceId, channelCode } = this.sourceData;
      axios({
        url: this.$config.m7sUrl + '/gb28181/api/control',
        params: {
          id: deviceId,
          channel: channelCode,
          ptzcmd: code
        }
      })
      setTimeout(() => {
        axios({
          url: this.$config.m7sUrl + '/gb28181/api/control',
          params: {
            id: deviceId,
            channel: channelCode,
            ptzcmd: 'A50F0100000000B5'
          }
        })
      }, 500);
    }
  },
  mounted() {

  }
};
</script>

<style lang="scss" scoped>
.player_tool {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0;
  bottom: 0;
  background-color: #00000090;

  .player_zoom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .reduce_zoom {
      width: 30px;
      height: 30px;
      margin-right: 30px;
      position: relative;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #52afe8;
        transition: background 0.2s ease-out;
      }

      &:hover {
        &::before {
          background: #2878ff;
        }
      }
    }

    .add_zoom {
      width: 30px;
      height: 30px;
      position: relative;
      cursor: pointer;

      &:hover {
        &::before {
          background: #2878ff;
        }

        &::after {
          background: #2878ff;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        background: #52afe8;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: background 0.2s ease-out;
      }

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 6px;
        height: 100%;
        background: #52afe8;
        left: 50%;
        transform: translateX(-50%);
        transition: background 0.2s ease-out;
      }
    }
  }

  .player_direct {
    width: 78px;
    height: 78px;
    background: #39405d;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;

    .direct_btn {
      position: absolute;
      cursor: pointer;
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 0.8;
      }
    }

    .camera_up {
      width: 30px;
      height: 16px;
      background: url(../../assets/icons/up_icon@2x.png) no-repeat center;
      background-size: 100%;
      left: 50%;
      top: 2px;
      margin-left: -15px;
    }

    .camera_bottom {
      width: 30px;
      height: 16px;
      background: url(../../assets/icons/down_icon@2x.png) no-repeat center;
      background-size: 100%;
      left: 50%;
      bottom: 2px;
      margin-left: -15px;
    }

    .camera_left {
      width: 16px;
      height: 30px;
      background: url(../../assets/icons/Left_icon@2x.png) no-repeat center;
      background-size: 100%;
      left: 2px;
      top: 50%;
      margin-top: -15px;
    }

    .camera_right {
      width: 16px;
      height: 30px;
      background: url(../../assets/icons/right_icon@2x.png) no-repeat center;
      background-size: 100%;
      right: 2px;
      top: 50%;
      margin-top: -15px;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      background: #cfe0ff;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      margin-left: -13px;
      margin-top: -13px;
    }
  }
}
</style>
