<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-12 15:13:51
 * @FilePath: /zhy_dataview_fe/src/components/InsidePages/MonitoringList.vue
 * @Description: 监控设备列表
-->
<template>
  <div class="MonitoringList">
    <el-tree ref="monitoringList" :data="list" show-checkbox node-key="id" default-expand-all :props="defaultProps"
      @check="onChange">
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span>{{ data.name }}</span>
        </span>
      </template>
    </el-tree>
  </div>
</template>

<script>
export default {
  name: "MonitoringList",
  data() {
    return {
      list: [],
      defaultProps: {
        label: 'name',
        children: 'childNodeList'
      },
    };
  },

  mounted() {
    this.getVideoDeviceContact();
  },

  methods: {
    // 获取视频地点列表
    getVideoDeviceContact() {
      this.$api.getTreeContacts().then((res) => {
        const { data } = res;
        this.list = data.nvrContactsList;
      });
    },

    // 点击勾选复选框
    onChange(data, allCheckedData) {
      const { checkedNodes } = allCheckedData;
      const filtersList = checkedNodes.filter((item) => {
        return item.rtspUrl;
      });
      this.$emit("checkedVideo", filtersList);
    },

    // 重置勾选
    removeCheck(arr) {
      this.$refs.monitoringList.setCheckedNodes(arr);
    },
  },
};
</script>

<style lang="scss">
.MonitoringList {
  padding: 0 10px;
  overflow-y: auto;

  .el-tree {
    background-color: transparent !important;

    .el-tree-node__content {
      height: 34px;
      background-color: #202331;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      .el-tree-node__label {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
      }

      .el-checkbox__input .el-checkbox__inner {
        background: transparent;
        border-color: rgba(255, 255, 255, 0.6);
      }

      .el-tree-node:focus {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
