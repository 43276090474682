<!--
 * @Author: Leif
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-20 19:11:51
 * @Description: 监控视频画面
-->
<template>
  <div class="MonitoringScreen">
    <ul :class="`infinite-list ${[type]} ${[typePlus]}`">
      <li v-for="(item, index) in sourceList" :key="index" @dblclick="showPlayerTools(item)">
        <div class="warpper">
          <VideoPlayer v-if="item.hdlUrl" :source="item.hdlUrl" :isFullScreen="false" />
          <div v-else style="color: #999999;">
            <p>当前暂无画面</p>
          </div>
        </div>
        <transition name="el-zoom-in-center">
          <VideoTools v-show="item.hdlUrl&&item.showTools" :sourceData="item" />
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
import VideoPlayer from "./VideoPlayer";
import VideoTools from "./VideoTools";
export default {
  name: "MonitoringScreen",
  data() {
    return {};
  },
  components: {
    VideoPlayer,
    VideoTools
  },
  props: {
    type: {
      type: String,
      default: "fourColumn",
    },
    typePlus: {
      type: String,
      default: "",
    },
    sourceNum: {
      type: Number,
      default: () => 16
    },
    sourceList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showBigPlayer(index) {
      const { hdlUrl, iccChannelCode } = this.sourceList[index] || {};
      if (hdlUrl) {
        this.$emit("showBigPlayer", {
          source: hdlUrl,
          channelId: iccChannelCode,
        });
      }
    },
    showPlayerTools(item) {
      const { hdlUrl } = item;
      if (hdlUrl) {
        item.showTools = !item.showTools
      }
    }
  },
  mounted() {
    for (let i = 0; i < this.sourceNum; i++) {
      this.sourceList.push(new Object)
    }
  },
  updated() {
    const delNum = this.sourceList.length - this.sourceNum;
    const addNum = this.sourceNum - this.sourceList.length;
    if (delNum > 0) {
      this.sourceList.pop()
    } else {
      for (let i = 0; i < addNum; i++) { this.sourceList.push(new Object) };
    }
    this.$emit('removeCheck', this.sourceList);
  },
};
</script>

<style lang="scss" scoped>
.MonitoringScreen {
  height: 780px;

  .infinite-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    height: 100%;

    li {
      position: relative;
      float: left;
      overflow: hidden;
      border: 1px solid #464d6f;
      box-sizing: border-box;
      color: #fff;
      transition: all 0.2s ease-out;
      cursor: pointer;

      &:hover {
        border-color: #6575c1;
      }

      .warpper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .fourColumn {
    li {
      width: calc(100% / 4);
      padding: calc((100% / 4) / 1.77) 0 0;
    }
  }

  .fourColumn_zoom {
    li {
      padding: calc((100% / 4) / 1.815) 0 0;
    }
  }

  .fourColumn_pack {
    li {
      width: calc(100% / 4);
      padding: calc((100% / 4) / 2.35) 0 0;
    }
  }

  .threeColumn {
    li {
      width: calc(100% / 3);
      padding: calc((100% / 3) / 1.76) 0 0;
    }
  }

  .threeColumn_zoom {
    li {
      padding: calc((100% / 3) / 1.82) 0 0;
    }
  }

  .threeColumn_pack {
    li {
      width: calc(100% / 3);
      padding: calc((100% / 3) / 2.35) 0 0;
    }
  }

  .twoColumn {
    li {
      width: calc(100% / 2);
      padding: calc((100% / 2) / 1.76) 0 0;
    }
  }

  .twoColumn_zoom {
    li {
      padding: calc((100% / 2) / 1.815) 0 0;
    }
  }

  .twoColumn_pack {
    li {
      width: calc(100% / 2);
      padding: calc((100% / 2) / 2.34) 0 0;
    }
  }

  .onePlusColumn {
    li {
      width: calc(100% / 4);
      padding: calc((100% / 4) / 1.763) 0 0;

      &:first-child {
        width: calc(100% / 1.334);
        padding: calc((100% / 1.35) / 1.73) 0 0;
      }
    }
  }

  .onePlusColumn_zoom {
    li {
      padding: calc((100% / 4) / 1.825) 0 0;

      &:first-child {
        width: calc(100% / 1.334);
        padding: calc((100% / 1.35) / 1.794) 0 0;
      }
    }
  }

  .onePlusColumn_pack {
    li {
      width: calc(100% / 4);
      padding: calc((100% / 4) / 2.34) 0 0;

      &:first-child {
        width: calc(100% / 1.334);
        padding: calc((100% / 1.3021) / 2.38) 0 0;
      }
    }
  }

  .oneColumn {
    li {
      width: 100%;
      padding: calc(100% / 1.75) 0 0;
    }
  }

  .oneColumn_zoom {
    li {
      padding: calc(100% / 1.815) 0 0;
    }
  }

  .oneColumn_pack {
    li {
      padding: calc(100% / 2.34) 0 0;
    }
  }
}
</style>
